import { useTranslations } from '@/composable/App/Translations';

import NotificationsTab from '@/components/Profile/tabs/NotificationsTab';
import PaymentMethodsTab from '@Shared/Payments/PaymentMethodsTab';

import PersonalInformationForm from '../components/PersonalInformationForm/PersonalInformationForm';

import accountSettingsRoutesNames from '../router/routes-names';
import DrivingLicenseTab from '../DrivingLicenseTab';
import BadgeTab from '../BadgeTab';

const { $t } = useTranslations();

export const computeAccountSettingsTabs = ({
  userCompany, hasCompanyAccessToOpenFleet, doesCompanyOwnsData, isBusinessProfileActive, canEmployeeChangeBusinessPaymentMethod,
}) => [
  {
    id: 'personal_information',
    label: $t('settings.personal.tab'),
    content: [
      {
        component: PersonalInformationForm,
      },
    ],
    url: accountSettingsRoutesNames.personalInformation,
    isVisible: true,
  },
  {
    id: 'driving_license',
    label: $t('settings.driving_license.tab'),
    content: [
      {
        component: DrivingLicenseTab,
      },
    ],
    url: accountSettingsRoutesNames.drivingLicense,
    isVisible: userCompany
      ? hasCompanyAccessToOpenFleet || !doesCompanyOwnsData
      : true,
  },
  {
    id: 'key_card',
    label: $t('settings.card.tab'),
    content: [
      {
        component: BadgeTab,
      },
    ],
    url: accountSettingsRoutesNames.badge,
    isVisible: true,
  },
  {
    id: 'payment_methods',
    label: $t('settings.payment_method.tab'),
    content: [
      {
        component: PaymentMethodsTab,
      },
    ],
    url: accountSettingsRoutesNames.paymentMethods,
    isVisible: isBusinessProfileActive
      ? canEmployeeChangeBusinessPaymentMethod
      : true,
  },
  {
    id: 'notifications',
    label: {
      title: $t('personal_profile.notification.tab'),
    },
    content: [
      {
        component: NotificationsTab,
      },
    ],
    url: accountSettingsRoutesNames.notifications,
    isVisible: true,
  },
];
