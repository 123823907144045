<template>
  <MainViewLayout>
    <template slot="main-content">
      <div class="phLayoutSettings LayoutPage">
        <div class="GenericTabs GenericTabs--Personal">
          <MuiTabs
            :active="currentTabIndex"
            :tabs="visibleTabs"
            class="TabsContent pt-5"
            @active-tab="goToTab"
          />
        </div>
      </div>
    </template>
  </MainViewLayout>
</template>
<script>
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import find from 'lodash/find';

import { navigationErrorHandler } from '@emobg/web-utils';
// Components
import { MuiTabs } from '@emobg/vue-base';
import MainViewLayout from '@/templates/MainLayout/MainLayout';
// Store Mappers
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';

// Routes
import accountSettingsRoutesNames from '@Account/Settings/router/routes-names';
import {
  canEmployeeChangeBusinessPaymentMethod, doesCompanyOwnsData, hasCompanyAccessToOpenFleet, userCompany,
} from '@/stores/User/UserData/UserDataMapper';
import { isBusinessProfileActive } from '@/stores/User/Profile/ProfileMapper';

import { checkRedirectFlowOnRouteEnter, checkUrlQueryOnPageLeave, removeCommonStorageOnRouterLeave } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';

import { HUB_REDIRECT_FLOW_STATUS } from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import { computeAccountSettingsTabs } from './helpers/accountSettingsHelpers';

export default {
  name: 'AccountSettings',

  components: {
    MainViewLayout,
    MuiTabs,
  },

  provide() {
    return {
      usingCompanyAdmin: false,
    };
  },

  beforeRouteLeave(_to, _from, next) {
    removeCommonStorageOnRouterLeave();

    next();
  },

  beforeRouteEnter(to, from, next) {
    checkRedirectFlowOnRouteEnter(from, to);

    next();
  },
  inheritAttrs: false,

  data() {
    return {
      routes: {
        ...accountSettingsRoutesNames,
      },
    };
  },

  computed: {
    hasCompanyAccessToOpenFleet,
    userCompany,
    doesCompanyOwnsData,
    canEmployeeChangeBusinessPaymentMethod,
    isBusinessProfileActive,

    visibleTabs() {
      const tabs = computeAccountSettingsTabs({
        userCompany: this.userCompany,
        hasCompanyAccessToOpenFleet: this.hasCompanyAccessToOpenFleet,
        doesCompanyOwnsData: this.doesCompanyOwnsData,
        canEmployeeChangeBusinessPaymentMethod: this.canEmployeeChangeBusinessPaymentMethod,
        isBusinessProfileActive: this.isBusinessProfileActive,
      });
      return filter(tabs, 'isVisible');
    },

    currentTabIndex() {
      return findIndex(this.visibleTabs, { url: this.$route.name });
    },

    isCurrentRoutePaymentMethods() {
      return this.isRoute(this.routes.paymentMethods);
    },
  },

  watch: {
    currentTabIndex(tabIndex) {
      if (tabIndex === -1) {
        this.goToTab(this.routes.personalInformation);
      }
    },
  },

  created() {
    this.setLoaderStatus(false);
    checkUrlQueryOnPageLeave();
    this.checkPaymentMethodTab();
  },

  methods: {
    setLoaderStatus,
    goToTab(routeName) {
      this.$router.push({ name: routeName }).catch(navigationErrorHandler);
    },

    isRoute(routeName) {
      return this.$route.name === routeName;
    },

    checkPaymentMethodTab() {
      const flowStatusRedirect = `${HUB_REDIRECT_FLOW_STATUS.fromRedirectQueryString}`;

      if (window.location.search.includes(flowStatusRedirect)) {
        return;
      }

      if (!get(find(this.visibleTabs, ['id', 'payment_methods']), 'isVisible') && this.isCurrentRoutePaymentMethods) {
        this.goToTab(this.routes.personalInformation);
      }
    },
  },
};
</script>
<style lang="scss">
.AccountSettingsTab__Container {
  max-width: 456px;
}
</style>
