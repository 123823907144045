import get from 'lodash/get';

export const getRequiredConfiguration = operator => {
  const {
    badge_pickup_available: badgePickupAvailable,
    badge_pickup_price: badgeCost,
    first_step_video_link: firstStepVideoLink,
  } = get(operator, 'configuration') || {};

  return {
    csOperatorUUID: get(operator, 'uuid'),
    badgePickupAvailable,
    badgeCost,
    firstStepVideoLink,
  };
};

export const requiredProperties = [
  'csOperatorUUID',
  'badgePickupAvailable',
  'badgeCost',
  'firstStepVideoLink',
];
