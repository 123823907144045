<template>
  <div data-test-id="keycard_view_initial-step">
    <h1
      class="my-3"
      v-html="$t('complete_documentation.new_custom_badge_flow.initial_step.title')"
    />

    <p class="mb-5 font-regular emobg-font-medium emobg-color-ink">
      <span v-html="$t('complete_documentation.new_custom_badge_flow.initial_step.how_it_works_text')" />
    </p>

    <KeyCardList class="mb-6" />

    <div class="d-inline-flex">
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        data-test-id="do_it_later-button"
        @clickbutton="closeFullScreen"
      >
        {{ $t('complete_documentation.manual_upload.presentation.do_it_later') }}
      </ui-button>

      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="isButtonDisabled"
        data-test-id="next_step-button"
        class="ml-3"
        @clickbutton="() => goToLinkExistingCard()"
      >
        {{ $t('buttons.next') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useTheme } from '@/composable/Theme/useTheme';

import KeyCardList from './components/KeyCardList';
import {
  fetchKeycardsTypes, goToLinkExistingCard, isButtonDisabled, setUserUuid,
} from '../KeyCardMapper';

export default {
  name: 'InitialStep',

  components: {
    KeyCardList,
  },

  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    csOperatorConfig: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const { trackPageView, trackModalView } = useTrackingGTM();
    const { fetchButtonSpecs } = useTheme();

    return { trackPageView, trackModalView, fetchButtonSpecs };
  },

  computed: {
    isButtonDisabled,
  },

  created() {
    this.trackModalView({ modalName: GTMModalEvents.keycardViewInitialStep });
    this.fetchKeycardsTypes({ csOperatorUUID: this.csOperatorConfig.csOperatorUUID });
    this.setUserUuid(this.user.userUuid);
  },

  methods: {
    fetchKeycardsTypes,
    goToLinkExistingCard,
    setUserUuid,

    closeFullScreen() {
      this.$fullScreen.close();
      this.trackPageView();
    },
  },
};
</script>
