<template>
  <div
    v-if="!isLoaderActive"
    class="container mt-5"
  >
    <div
      class="PersonalInformation__Container d-flex flex-column ml-5 mb-6"
      data-test-id="personal_information-form"
    >
      <h2 class="mb-3">
        {{ $t('settings.personal.title') }}
      </h2>
      <ui-form validate>
        <AlertComponent
          v-if="hasBusinessProfileAndNotFRCorporate"
          :type="ALERT_TYPES.informative"
          class="d-block w-100 mt-1 mb-3"
          data-test-id="alert-block"
        >
          <div v-html="formatedCsOperatorMailto" />
        </AlertComponent>
        <ui-validate @status="({ detail }) => formStatus = detail">
          <div class="PersonalInformation__Inputs d-flex flex-column">
            <ui-text-input
              :label="$t('settings.personal.login_label')"
              :placeholder="$t('settings.personal.login_label')"
              :value="transitionData.email"
              :type="INPUT_TYPES.email"
              name="email"
              data-test-id="personal_information-email"
              class="d-block mt-1 mb-3"
              disabled
              data-cs-mask
            />

            <ui-text-input
              :label="$t('settings.personal.password_label')"
              :placeholder="$t('settings.personal.password_label')"
              :value="password"
              :type="INPUT_TYPES.password"
              name="email"
              data-test-id="personal_information-password"
              class="d-block mt-1 mb-3"
              disabled
              data-cs-mask
            />

            <ui-button
              v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
              class="ml-1 emobg-font-weight-semibold emobg-font-line-height-large"
              data-test-id="personal_information-change-password"
              @clickbutton="openChangePasswordModal"
            >
              {{ $t("settings.links.change_password") }}
            </ui-button>

            <ui-text-input
              ref="first-name"
              :label="$t('settings.personal.name_label')"
              :placeholder="$t('settings.personal.name_label')"
              :value="transitionData.firstName"
              :disabled="hasBusinessProfileAndNotFRCorporate"
              name="first-name"
              data-test-id="personal_information-firstname"
              class="d-block mt-1 mb-3"
              data-cs-mask
              @changevalue="({ detail }) => transitionData.firstName = detail"
            />

            <ui-text-input
              ref="last-name"
              :label="$t('settings.personal.last_name_label')"
              :placeholder="$t('settings.personal.last_name_label')"
              :value="transitionData.lastName"
              :disabled="hasBusinessProfileAndNotFRCorporate"
              name="last-name"
              data-test-id="personal_information-lastname"
              class="d-block mt-1 mb-1"
              data-cs-mask
              @changevalue="({ detail }) => transitionData.lastName = detail"
            />

            <ui-checkbox
              v-if="!isDirectMarketingSelectable"
              :checked="transitionData.directMarketing"
              :caption="$t('views.legal_documents.signup_text_newsletter', { operator: appName })"
              :size="SIZES.small"
              data-masked
              data-test-id="personal_information-allow-marketing"
              name="newsletter"
              class="d-block mt-1 mb-3"
              @changevalue="({ detail }) => transitionData.directMarketing = toBoolean(detail)"
            />

            <MuiInputLocation
              ref="location"
              :label="$t('settings.personal.address_label')"
              :placeholder="$t('settings.personal.address_label')"
              :autocomplete-service="googleMapsAutocompleteService"
              :geocoder-service="googleMapsGeocoderService"
              :value="transitionData.address"
              :custom-predictions="[]"
              :allowed-countries="[]"
              :disabled="hasBusinessProfileAndNotFRCorporate"
              data-test-id="personal_information-address"
              class="d-block w-100 mt-1 mb-3"
              data-cs-mask
              @change="location => transitionData.address = location.address"
            />

            <ui-text-input
              ref="address-details"
              :label="$t('settings.personal.address_details_label')"
              :placeholder="$t('settings.personal.address_details_placeholder')"
              :value="transitionData.addressDetails"
              :disabled="hasBusinessProfileAndNotFRCorporate"
              name="address-details"
              data-test-id="personal_information-address_details"
              class="d-block mt-1 mb-3"
              data-cs-mask
              @changevalue="({ detail }) => transitionData.addressDetails = detail"
            />

            <ui-text-input
              v-if="includes(userSpecificFieldsKeys, specificFieldsKeys.codiceFiscale)"
              ref="codice-fiscale"
              :label="$t('settings.personal.countries.italy.codice_fiscale')"
              :placeholder="$t('settings.personal.countries.italy.codice_fiscale')"
              :value="transitionData.codiceFiscale"
              :disabled="hasBusinessProfileAndNotFRCorporate"
              name="codice-fiscale"
              data-test-id="personal_information-codice-fiscale"
              data-cs-mask
              class="d-block mt-1 mb-3"
              @changevalue="({ detail }) => transitionData.codiceFiscale = detail"
            />

            <ui-select
              :value="transitionData.locale"
              :options.prop="availableLanguages"
              :label="$t('select_language.title')"
              :placeholder="$t('select_language.title')"
              name="language"
              class="d-block w-100 mt-1 mb-3"
              data-cs-mask
              data-test-id="personal_information-language-selector"
              @selectoption="({ detail }) => transitionData.locale = detail"
            />
          </div>
          <div class="PersonalInformation__Actions">
            <ui-button
              v-bind="fetchButtonSpecs()"
              :disabled="!isSubmitEnabled"
              :loading="isLoading"
              :type="BUTTON_TYPES.submit"
              class="d-block w-100 mt-2 mb-3"
              data-test-id="personal_information-submit"
              @clickbutton="submit"
            >
              {{ $t("settings.action.save") }}
            </ui-button>
          </div>
        </ui-validate>
      </ui-form>

      <div
        v-if="canCancelAccount"
        class="pt-4 mt-3 emobg-border-top-1 emobg-border-color-ground-light"
      >
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.DESTRUCTIVE })"
          data-test-id="cancel-account-button"
          @clickbutton="openCancelAccountModal"
        >
          {{ $t('settings.action.cancel_account') }}
        </ui-button>
      </div>
      <p v-else>
        {{ $t('settings.personal.delete_employee_message', { operator: appName }) }}
      </p>

      <FeedbackModalComponent
        v-model="modals.active.isOpen"
        v-bind="modals.active"
        @close-modal="closeActiveModal"
      />

      <FeedbackModalComponent
        v-model="modals.resetPassword.isOpen"
        v-bind="modals.resetPassword"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import pick from 'lodash/pick';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import isMatch from 'lodash/isMatch';
import intersection from 'lodash/intersection';
import values from 'lodash/values';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import map from 'lodash/map';

import { MuiInputLocation } from '@emobg/vue-internal';
import { external } from '@emobg/web-api-client';
import { toBoolean } from '@emobg/web-utils';
import { Validate } from '@emobg/vue-base';
import config from '@/config';

import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import AlertComponent from '@/components/Alert/AlertComponent';

import { genericConfirmArgs, genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import ALERT_TYPES from '@/components/Alert/alertTypes';

import AuthenticatedUserMixin from '@/mixins/AuthenticatedUser';

import Translations from '@/translations';
import languageService from '@/services/Language';
import { initAuthAxiosClientConfig } from '@/vue/api/api.service';

import {
  doesCompanyOwnsData, updateDirectMarketing, updateUserPersonalData, userLocale, userUuid,
} from '@/stores/User/UserData/UserDataMapper';
import { cancelUserAccount, fetchUserAccount, getPersonalInformation } from '@/stores/User/UserMapper';
import { isLoaderActive, setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { hasBusinessProfile, hasOnlyBusinessProfile } from '@/stores/User/Profile/ProfileMapper';
import {
  fetchCSOperator,
  getCurrentCSOperator,
  getCurrentOperatorServiceEmail,
  resetCSOperatorStore,
} from '@/stores/CSOperator/CSOperatorMapper';

import { useAuth } from '@/composable/Api/Auth/auth';
import { useMaps } from '@/composable/App/Map/useMaps';
import { useTheme } from '@/composable/Theme/useTheme';

import { composeAlertEmailToLinkData } from '@/helpers/user/personalInformation/personalInformationHelpers';
import { resetPasswordSuccess } from '@/utils/publicImages';
import { requiredUserDataFields, specificFieldsKeys } from './constants/specificFieldsByCountry';

export default {
  name: 'PersonalInformationForm',

  components: {
    MuiInputLocation,
    FeedbackModalComponent,
    AlertComponent,
  },

  directives: {
    Validate,
  },

  mixins: [AuthenticatedUserMixin],

  setup() {
    const { logout } = useAuth();
    const { trackModalView, trackPageView } = useTrackingGTM();
    const { googleMapsAutocompleteService, googleMapsGeocoderService } = useMaps();
    const { fetchButtonSpecs } = useTheme();

    return {
      logout,
      trackModalView,
      trackPageView,
      googleMapsAutocompleteService,
      googleMapsGeocoderService,
      fetchButtonSpecs,
    };
  },

  data() {
    return {
      formStatus: {},
      userSpecificFieldsKeys: [],
      password: '123456789',
      initialData: {},
      transitionData: {},
      availableLanguages: [],
      isLoading: false,
      gmapApi: {},
      specificFields: [],
      isChangePasswordModalOpen: false,
      modals: {
        active: {
          isOpen: false,
        },
        resetPassword: {
          isOpen: false,
        },
      },
    };
  },

  computed: {
    getPersonalInformation,
    userLocale,
    userUuid,
    doesCompanyOwnsData,
    isLoaderActive,
    hasOnlyBusinessProfile,
    hasBusinessProfile,
    getCurrentOperatorServiceEmail,
    getCurrentCSOperator,

    canCancelAccount() {
      return !this.hasOnlyBusinessProfile;
    },

    isSubmitEnabled() {
      return this.hasChangedData && this.areAllValid;
    },

    mandatoryPersonalData() {
      return pick(this.transitionData, ['firstName', 'lastName', 'address']);
    },

    areAllValid() {
      return !some(this.mandatoryPersonalData, isEmpty);
    },

    hasChangedData() {
      return this.hasUserPersonalDataChanged || this.isLocaleChanged || this.isDirectMarketingChanged;
    },

    editablePersonalData() {
      return pick(this.transitionData, ['firstName', 'lastName', 'address', 'addressDetails']);
    },

    hasUserPersonalDataChanged() {
      return !isMatch(this.initialData, this.editablePersonalData);
    },

    isLocaleChanged() {
      return get(this, 'initialData.locale') !== get(this, 'transitionData.locale');
    },

    isDirectMarketingChanged() {
      return (get(this, 'initialData.directMarketing') !== get(this, 'transitionData.directMarketing'));
    },

    isDirectMarketingSelectable() {
      const isNotificationCenterEnabled = get(this, '$featureFlag.flags.notificationCenter');

      return !this.doesCompanyOwnsData && !isNotificationCenterEnabled;
    },

    // TODO: Remove FF and simplify code once migration issue is resolved
    // https://europcarmobility.atlassian.net/browse/CSTMR-5918
    hasBusinessProfileAndNotFRCorporate() {
      return !this.hasBusinessProfile || get(this, '$featureFlag.flags.cstmr5917DisableEditAddressPersonalInfoForm');
    },

    formatedCsOperatorMailto() {
      return composeAlertEmailToLinkData({ serviceEmail: this.getCurrentOperatorServiceEmail });
    },

    customerServiceOpeningHours() {
      return get(this, 'getCurrentCSOperator.customer_service_opening_hours');
    },
  },

  async created() {
    this.setLoaderStatus(true);
    this.ALERT_TYPES = ALERT_TYPES;

    this.appName = config.data.appName;
    this.specificFieldsKeys = specificFieldsKeys;

    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('modal.personal_information.success.text'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
        this.$emit('close-modal');
      },
    };
    this.successCancelAccountModalArgs = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('modal.account.title'),
      primaryCallToAction: () => {
        this.modal.active.isOpen = false;
      },
    };
    this.errorModalArgs = {
      ...genericErrorArgs(this.$t),
      primaryCallToActionText: this.$t('modal.cancel_booking.error.try_again'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
      },
    };

    await this.fetchUserAccount({ userUuid: this.userUuid });
    const availableLanguagesData = await external.app.getAvailableLanguages();
    this.availableLanguages = map(availableLanguagesData, ({ name, key }) => ({ label: name, value: key }));

    this.setInitialData();

    this.setLoaderStatus(false);
  },

  methods: {
    toBoolean,
    get,
    includes,
    setLoaderStatus,
    updateDirectMarketing,
    updateUserPersonalData,
    fetchCSOperator,
    fetchUserAccount,
    cancelUserAccount,
    resetCSOperatorStore,

    setInitialData() {
      this.userSpecificFieldsKeys = intersection(values(specificFieldsKeys), keys(this.getPersonalInformation));
      this.initialData = {
        ...pick(this.getPersonalInformation, [...requiredUserDataFields, ...this.userSpecificFieldsKeys]),
        locale: this.userLocale,
      };

      this.transitionData = { ...this.initialData };
    },

    async submit() {
      this.isLoading = true;

      const dataToUpdate = [];

      if (this.isLocaleChanged) {
        this.updateLocale();
      }

      if (this.hasUserPersonalDataChanged) {
        dataToUpdate.push(this.updatePersonalData());
      }

      if (this.isDirectMarketingChanged) {
        dataToUpdate.push(this.updateUserDirectMarketing());
      }

      try {
        await Promise.all(dataToUpdate);

        await this.refreshData();

        this.modals.active = { ...this.successModalArgs, isOpen: true };
      } catch (error) {
        this.modals.active = { ...this.errorModalArgs, isOpen: true };
      } finally {
        this.isLoading = false;
      }
    },

    async cancelAccount() {
      this.isLoading = true;

      try {
        await this.cancelUserAccount({ userUuid: this.userUuid });

        this.modals.active = { ...this.successCancelAccountModalArgs, isOpen: true };
      } catch (error) {
        this.modals.active = { ...this.errorModalArgs, isOpen: true };
      } finally {
        setTimeout(() => {
          this.logout();
        }, 5000);
      }
    },

    openCancelAccountModal() {
      this.trackModalView({ modalName: GTMModalEvents.cancelAccount });
      this.modals.active = {
        ...genericConfirmArgs(this.$t),
        primaryButtonType: this.THEME_BUTTON_TYPES.DESTRUCTIVE,
        secondaryButtonType: this.THEME_BUTTON_TYPES.SECONDARY,
        title: this.$t('modal.confirm.title'),
        primaryCallToActionText: this.$t('settings.action.delete'),
        primaryCallToAction: this.cancelAccount,
        secondaryCallToAction: () => {
          this.modals.active.isOpen = false;
        },
        isOpen: true,
      };
    },

    updateLocale() {
      const locale = this.setLanguage(get(this, 'transitionData.locale'));
      initAuthAxiosClientConfig({ locale });
    },

    async updatePersonalData() {
      await this.updateUserPersonalData({ userUuid: this.userUuid, data: this.editablePersonalData });
    },

    async updateUserDirectMarketing() {
      await this.updateDirectMarketing();
    },

    async refreshData() {
      await this.refreshAuthenticatedUserData();
      this.setInitialData();

      const locale = this.setLanguage(this.userLocale);
      this.refreshLiterals(locale);
    },

    async refreshAuthenticatedUserData() {
      await this.fetchUserAccount({ userUuid: this.userUuid });
      const csOperatorUuid = get(this.getCurrentCSOperator, 'uuid');
      await this.resetCSOperatorStore();
      await this.fetchCSOperator(csOperatorUuid).then(operator => this.setCurrentCSOperator(operator));
      const userDetailsResponse = await this.fetchAuthenticatedUser();
      await this.setAuthenticatedUser(userDetailsResponse);
    },

    setLanguage(localeCode) {
      const language = languageService.getLocaleFromLanguageCode(localeCode);
      languageService.setForceLanguage(language);

      return language;
    },

    refreshLiterals(locale) {
      moment.locale(locale);
      Translations.setDefaultLanguage(locale);
    },

    openErrorModal() {
      this.modals.resetPassword = {
        ...this.modals.resetPassword,
        ...genericErrorArgs(this.$t),
        description: this.$t('modal.personal_information.error.description', { customerServiceOpeningHours: this.customerServiceOpeningHours }),
        isOpen: true,
        primaryCallToAction: () => {
          this.modals.resetPassword = { ...this.modals.resetPassword, isOpen: false };
        },
        primaryCallToActionText: this.$t('buttons.got_it'),
      };
    },

    openSuccessModal() {
      this.modals.resetPassword = {
        ...this.modals.resetPassword,
        ...genericSuccessArgs(this.$t),
        description: this.$t('modal.personal_information.success.description'),
        image: resetPasswordSuccess,
        isOpen: true,
        primaryCallToAction: () => {
          this.modals.resetPassword = { ...this.modals.resetPassword, isOpen: false };
        },
        primaryCallToActionText: this.$t('buttons.got_it'),
        title: this.$t('modal.personal_information.success.header'),
      };
    },

    closeActiveModal() {
      this.modals.active = {
        ...this.modals.active,
        isOpen: false,
        primaryCallToActionLoading: false,
        secondaryCallToActionDisabled: false,
      };
    },

    openChangePasswordModal() {
      this.modals.active = {
        description: this.$t('modal.personal_information.description', { email: this.transitionData.email }),
        isOpen: true,
        isClosable: true,
        primaryCallToAction: async () => {
          const { postResetPassword } = external.userCompanyUserAccount;

          try {
            this.modals.active = {
              ...this.modals.active,
              primaryCallToActionLoading: true,
              secondaryCallToActionDisabled: true,
            };
            await postResetPassword(this.transitionData.email);
            this.closeActiveModal();
            this.openSuccessModal();
          } catch (error) {
            this.closeActiveModal();
            this.openErrorModal();
          }
        },
        primaryCallToActionText: this.$t('buttons.confirm'),
        secondaryCallToAction: this.closeActiveModal,
        secondaryCallToActionText: this.$t('buttons.cancel'),
        testIdSuffix: 'change_password',
        title: this.$t('modal.personal_information.header'),
      };
    },
  },
};
</script>
<style lang="scss">
.PersonalInformation {
  &__Container {
    max-width: 456px;
  }
}

[data-test-id="personal_information-change-password"] > .Ui-Button {
  padding: 0;
}
</style>
