<template>
  <div
    data-test-id="keycard-list"
    class="KeyCardList"
  >
    <template v-if="size(customBadges)">
      <div
        class="mb-2 emobg-font-weight-bold emobg-font-default emobg-color-ink"
        v-html="$t('complete_documentation.new_custom_badge_flow.initial_step.link_keycard_title')"
      />
      <p
        class="emobg-font-default emobg-color-ink"
        v-html="$t('complete_documentation.new_custom_badge_flow.initial_step.link_keycard_text')"
      />

      <div class="mb-5 pb-2">
        <div
          v-for="card in customBadges"
          :key="card.uuid"
          class="emobg-background-color-ground-lighter emobg-border-radius-medium my-2 pt-1 px-2"
        >
          <ui-radio
            :value="selectedCard"
            :option="card.uuid"
            :caption="card.name"
            :data-test-id="`card-item-${card.internalName}`"
            :data-test-name="card.name"
            :size="SIZES.large"
            reverse
            name="keyCard"
            @changevalue="({ detail }) => selectedCard = detail"
          />
        </div>
      </div>
    </template>

    <ui-alert
      v-if="isLoaded && !hasKeycards"
      :color="COLORS.warning"
      :icon="ICONS.alert"
      data-test-id="keycard-list-alert"
      class="w-100"
    >
      <div v-html="$t('complete_documentation.new_custom_badge_flow.initial_step.no_keycard_availables')" />
    </ui-alert>
  </div>
</template>

<script>
import size from 'lodash/size';
import { mapGetters, mapMutations, mapState } from 'vuex';
import config from '@/config';
import * as KeycardModule from '../../KeyCardModule';

export default {
  name: 'KeyCardList',

  computed: {
    selectedCard: {
      get() {
        return this.keyCardSelected;
      },
      set(newSelectedCardUuid) {
        this.setKeyCardUuidSelected(newSelectedCardUuid);
      },
    },
    ...mapState(KeycardModule.NAMESPACE, {
      keyCardSelected: state => state.formData.cardType,
    }),

    ...mapGetters(KeycardModule.NAMESPACE, {
      customBadges: KeycardModule.GETTERS.getCustomKeyCards,
      isLoaded: KeycardModule.GETTERS.isKeycardLoaded,
    }),

    hasKeycards() {
      return size(this.customBadges);
    },
  },

  created() {
    this.appName = config.data.appName;
  },

  methods: {
    size,
    ...mapMutations(KeycardModule.NAMESPACE, {
      setKeyCardUuidSelected: KeycardModule.MUTATIONS.setKeycardType,
    }),
  },
};
</script>

<style lang="scss">
.KeyCardList {
  .Ui-Radio__circle {
    position: absolute !important;
    right: 10px;
  }

  .Ui-Radio {
    width: 100%;
  }
}
</style>
