import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import * as KeyCardModule from './KeyCardModule';

export const {
  currentStepComponent,
  deliveryAddress,
  isButtonDisabled,
  fetchKeycardsTypes,
  goToInitialStep,
  goToLinkExistingCard,
  selectedKeyCard,
  isNextButtonDisabled,
  linkingKeycardError,
  pickUpSelected,
  resetKeyCardState,
  setAddressDetailsForm,
  setAddressForm,
  setUserUuid,
  submitKeycardFormByMail,
  submitKeycardFormLinkCard,
  submitKeycardFormPickUp,
} = {
  ...mapGetters(KeyCardModule.NAMESPACE, {
    isButtonDisabled: KeyCardModule.GETTERS.isFirstStepDisabled,
    selectedKeyCard: KeyCardModule.GETTERS.selectedKeyCard,
    isNextButtonDisabled: KeyCardModule.GETTERS.isNextButtonDisabled,
  }),

  ...mapActions(KeyCardModule.NAMESPACE, {
    fetchKeycardsTypes: KeyCardModule.ACTIONS.fetchKeycardsTypes,
    goToLinkExistingCard: KeyCardModule.ACTIONS.goToLinkExistingCard,
    goToInitialStep: KeyCardModule.ACTIONS.goToInitialStep,
    setUserUuid: KeyCardModule.ACTIONS.setUserUuid,
    submitKeycardFormByMail: KeyCardModule.ACTIONS.submitKeycardFormByMail,
    submitKeycardFormLinkCard: KeyCardModule.ACTIONS.submitKeycardFormLinkCard,
    submitKeycardFormPickUp: KeyCardModule.ACTIONS.submitKeycardFormPickUp,
  }),

  ...mapMutations(KeyCardModule.NAMESPACE, {
    setAddressDetailsForm: KeyCardModule.MUTATIONS.setAddressDetailsForm,
    setAddressForm: KeyCardModule.MUTATIONS.setAddressForm,
    resetKeyCardState: KeyCardModule.MUTATIONS.resetData,
  }),

  ...mapState(KeyCardModule.NAMESPACE, {
    deliveryAddress: state => state.formData.deliveryAddress,
    pickUpSelected: state => state.formData.pickUpSelected,
    currentStepComponent: state => state.data.step,
    linkingKeycardError: state => state[KeyCardModule.SCOPE.linkCard].error,
  }),
};
