<template>
  <div class="DrivingLicenseTab container mt-4">
    <div class="AccountSettingsTab__Container ml-5 mb-6">
      <h2 class="mb-3">
        {{ $t('settings.driving_license.tab') }}
      </h2>
      <div v-if="!isEmpty(getUserDrivingLicense)">
        <ui-text-input
          name="licenseNumber"
          :label="$t('settings.driving_license.id_number')"
          :value="getUserDrivingLicense.licenseNumber"
          class="d-block my-4"
          data-test-id="license_number-input_text"
          data-cs-mask
          disabled
        />

        <ui-text-input
          name="licenseExpiration"
          :label="$t('settings.driving_license.expire')"
          :value="drivingLicenseDateFormatted"
          class="d-block my-4"
          data-test-id="license_expiration-input_text"
          data-cs-mask
          disabled
        />

        <ui-text-input
          name="licenseCountry"
          :label="$t('settings.driving_license.country')"
          :value="getUserDrivingLicense.country"
          class="d-block my-4"
          data-test-id="licensce_country-input_text"
          data-cs-mask
          disabled
        />

        <p class="my-3">
          {{ $t('settings.driving_license.no_desktop') }}
        </p>
      </div>

      <div v-else>
        <EmptyStateComponent
          has-background
          :src="document"
          :title="$t('settings.driving_license.no_driving_license')"
          :description="$t('settings.no_desktop')"
        />
      </div>
      <FeedbackModalComponent
        v-model="feedbackModal.isOpen"
        v-bind="feedbackModal"
      />
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { mapMutations, mapState } from 'vuex';
import { formatDate } from '@emobg/web-utils';
// Components
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import DrivingLicenseRoadblockComponent from '@/components/DrivingLicenseRoadblock/DrivingLicenseRoadblockComponent';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
// Constants
import { fetchUserAccount, getUserDrivingLicense } from '@/stores/User/UserMapper';
import { fetchCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import {
  fetchUserDetails, userCompanyUuid, userData, userUuid,
} from '@/stores/User/UserData/UserDataMapper';
import {
  MUTATIONS as DrivingLicenseRoadblockMutations,
  NAMESPACE as DrivingLicenseRoadblockNameSpace,
} from '@/components/DrivingLicenseRoadblock/DrivingLicenseRoadblockModule';

import { document } from '@/utils/publicImages';

import { genericConfirmArgs } from '@/constants/defaultModalArgs';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

export default {
  name: 'DrivingLicenseTab',
  components: {
    EmptyStateComponent,
    FeedbackModalComponent,
  },
  props: {
    openDrivingLicenseForm: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { notifyError } = useNotifications();
    return { notifyError };
  },
  data() {
    return {
      feedbackModal: {
        isOpen: false,
      },
    };
  },
  computed: {
    ...mapState(DrivingLicenseRoadblockNameSpace, {
      drivingLicenseCreated: state => state.data.drivingLicenseCreated,
      drivingLicenseNotCreated: state => state.data.drivingLicenseNotCreated,
      drivingLicenseFinishedWithBadge: state => state.data.drivingLicenseFinishedWithBadge,
    }),
    userData,
    userCompanyUuid,
    userUuid,
    getUserDrivingLicense,
    drivingLicenseDateFormatted() {
      return formatDate(get(this.getUserDrivingLicense, 'expiration'), DATETIME_FORMATS.shortMonthLongYear);
    },
  },
  watch: {
    drivingLicenseCreated(drivingLicenseCreated) {
      if (drivingLicenseCreated) { this.handleDrivingLicenseCreated(); }
    },
    drivingLicenseNotCreated(error) {
      if (error) { this.handleDrivingLicenseNotCreated(error); }
    },
    drivingLicenseFinishedWithBadge(isLicenseSet) {
      if (isLicenseSet) { this.handleDrivingLicenseFinishedWithBadge(); }
    },
  },
  created() {
    this.document = document;
  },
  mounted() {
    if (this.openDrivingLicenseForm) {
      this.feedbackModal = {
        ...genericConfirmArgs(this.$t),
        title: this.$t('modal.driving_license_confirmation.title'),
        description: this.$t('modal.driving_license_confirmation.subtitle'),
        primaryCallToActionText: this.$t('modal.driving_license_confirmation.replace'),
        primaryCallToAction: () => this.openCompleteDocumentation(),
        secondaryCallToAction: () => {
          this.feedbackModal.isOpen = false;
        },
        isOpen: true,
      };
    }
  },

  methods: {
    fetchUserAccount,
    fetchUserDetails,
    fetchCSOperator,
    isEmpty,

    ...mapMutations(DrivingLicenseRoadblockNameSpace, {
      resetDLRoadblockState:
        DrivingLicenseRoadblockMutations.RESET_DATA,
      resetDLCreated:
        DrivingLicenseRoadblockMutations.RESET_DRIVING_LICENCE_CREATED,
      resetDLNotCreated:
        DrivingLicenseRoadblockMutations.RESET_DRIVING_LICENCE_NOT_CREATED,
    }),

    async openCompleteDocumentation() {
      try {
        const csOperator = await this.fetchCSOperator(this.userUuid);

        this.$fullScreen.show(DrivingLicenseRoadblockComponent, {
          props: {
            userUuid: this.userUuid,
            csOperator,
            hasBadge: true,
          },
        });
      } catch (error) {
        this.notifyError({
          text: error || 'Error!',
        });
      }
    },

    async handleDrivingLicenseCreated() {
      this.resetDLCreated();
      Promise.all([
        this.fetchUserDetails(),
        this.fetchUserAccount({
          userUuid: this.userUuid,
        }),
      ]).catch(error => {
        this.handleDrivingLicenseNotCreated(error.message);
      });
    },

    handleDrivingLicenseNotCreated(error) {
      this.resetDLNotCreated();
      this.$fullScreen.close();

      this.notifyError({
        text: error || 'Error!',
      });
    },

    handleDrivingLicenseFinishedWithBadge() {
      this.resetDLRoadblockState();
      this.$fullScreen.close();
    },
  },
};
</script>
