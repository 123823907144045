import get from 'lodash/get';
import { COUNTRIES_ISO_CODES } from '@emobg/web-utils';
import { MuiInputText } from '@emobg/motion-ui/v1';

import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

const DATA_TEST_ID_PREFIX = 'personal_information';

export const specificFieldsKeys = Object.freeze({
  codiceFiscale: 'codiceFiscale',
});

export const requiredUserDataFields = ['firstName', 'lastName', 'address', 'addressDetails', 'directMarketing', 'email', 'addressCountryCode'];

export const specificInputFields = ({ personalInformation }) => ({
  [specificFieldsKeys.codiceFiscale]: {
    component: MuiInputText,
    props: {
      name: 'codice_fiscale',
      disabled: true,
      label: $t('settings.personal.countries.italy.codice_fiscale'),
      placeholder: $t('settings.personal.countries.italy.codice_fiscale'),
      modelValue: get(personalInformation, specificFieldsKeys.codiceFiscale),
      dataMasked: true,
    },
    dataTestId: `${DATA_TEST_ID_PREFIX}-codice_fiscale`,
    countries: [COUNTRIES_ISO_CODES.italy],
  },
});
