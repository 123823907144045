<template>
  <LayoutTemplate
    data-test-id="keycard_view_wrapper"
    :left-image="keyCardConfigureSideImage"
    is-cover-img
  >
    <div class="full-width mt-4 pt-8 d-flex justify-content-center">
      <div class="Step__form">
        <Component
          :is="currentStepComponent"
          v-bind="$props"
          @on:keycard-linked="$emit('on:keycard-linked')"
          @on:keycard-requested="$emit('on:keycard-requested')"
          @on:keycard-picked-up="$emit('on:keycard-picked-up')"
          @on:error="error => $emit('on:error', error)"
          @on:close="$emit('on:close')"
        />
      </div>
    </div>
  </LayoutTemplate>
</template>

<script>
import every from 'lodash/every';
import has from 'lodash/has';

import LayoutTemplate from '@Account/SignUp/Templates/SignUpTemplate';

import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import { currentStepComponent, resetKeyCardState } from './KeyCardMapper';
import { requiredProperties } from './helpers/requireOperatorConfig';
import InitialStep from './ConfigureKeyCard/InitialStep';
import LinkExisting from './ConfigureKeyCard/LinkExisting';

export default {
  name: 'Keycard',

  components: {
    LayoutTemplate,
    InitialStep,
    LinkExisting,
  },

  inheritAttrs: false,

  props: {
    user: {
      type: Object,
      required: true,
      validator: arg => {
        const requiredAttrs = ['address', 'address_details', 'defaultCity', 'userUuid'];
        const propAttrs = Object.keys(arg);
        return requiredAttrs.every(key => propAttrs.includes(key));
      },
    },
    csOperatorConfig: {
      type: Object,
      required: true,
      validator: csOperatorConfig => every(requiredProperties, prop => has(csOperatorConfig, prop)),
    },
  },

  computed: {
    currentStepComponent,
    keyCardConfigureSideImage: () => getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.KEYCARD),
  },

  created() {
    this.resetKeyCardState();
  },

  methods: {
    resetKeyCardState,
  },
};
</script>
